<script setup>
import AppModal from "@/components/Ui/AppModal.vue";
import { ref, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const emit = defineEmits(["close"]);
const props = defineProps({
  lesson: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const button_loader = ref(false);
const route = useRoute();
const router = useRouter();

const startOnlineLesson = () => {
  let data = structuredClone(props.lesson.data);
  data.status = "started";

  if (!button_loader.value) {
    button_loader.value = true;

    if (
      props.lesson.type === "webinar" &&
      props.lesson.data.type === "browser"
    ) {
      store
        .dispatch("lesson/createWebinarRoom", {
          courseId: props.lesson.course_id || route.params.id,
          unitId: props.lesson.id,
          data: {
            duration_minutes: props.lesson.data.time.value,
            visibility: props.lesson.data.access,
          },
        })
        .then((resp) => {
          data.webinar_room = resp.data.data.slug;

          store
            .dispatch("lesson/editLessonInformation", {
              course_id: props.lesson.course_id || route.params.id,
              id: props.lesson.id,
              data: {
                data: data,
              },
            })
            .then(() => {
              router.push({
                name: "WebinarView",
                params: { id: resp.data.data.slug },
              });
            })
            .catch((error) => {
              store.commit("system/SET_NOTIFICATION", {
                type: "error",
                name: "Что-то пошло не так",
                description: error.response.data.message,
              });
              emit("close");
            });
        });

      return;
    }

    store
      .dispatch("lesson/editLessonInformation", {
        course_id: props.lesson.course_id || route.params.id,
        id: props.lesson.id,
        data: {
          data: data,
        },
      })
      .catch((error) => {
        store.commit("system/SET_NOTIFICATION", {
          type: "error",
          name: "Что-то пошло не так",
          description: error.response.data.message,
        });
        emit("close");
      })
      .finally(() => {
        emit("close");
      });
  }
};
</script>

<template>
  <AppModal @close="emit('close')">
    <div class="default-modal">
      <h3>
        Запуск {{ lesson.type === "webinar" ? "вебинара" : "онлайн-встречи" }}
      </h3>

      <p>
        После запуска
        {{ lesson.type === "webinar" ? "вебинара" : "онлайн-встречи" }} вы не
        сможете редактировать
        {{ lesson.type === "webinar" ? "его" : "ее" }} параметры кроме названия
        и описания. Запустить
        {{ lesson.type === "webinar" ? "вебинар" : "онлайн-встречу" }}?
      </p>

      <div class="default-modal__actions">
        <button
          class="button button_large"
          @click="startOnlineLesson"
          :disabled="button_loader"
        >
          Запустить
        </button>

        <button
          @click="emit('close')"
          class="button button_large button_secondary"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style scoped lang="scss"></style>
